/* This file is called when an exam is completed by the invigilator */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Http from 'axios'
import NewLogin from '../Auth/NewLogin'

class NewExamCompleted extends Component {

  constructor() {
    super();
    this.state = {
      isLogged: false,
      isLoaded: false,
      users: [],
      userMenu: false,
      menu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }
  async componentWillMount() {
		if(this.state.user=JSON.parse(localStorage.getItem('user')))
                   this.state.isLogged=true;
		else{
		    console.log("User not Logged in");
                    this.state.isLogged=false;
		}
    const exam = JSON.parse(localStorage.getItem('exam1'));
    this.state.exam = exam;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state.user = user;
    var api;
    if (exam)
      api = 'user-api/userua/' + exam.id;
    else
      api = 'user-api/user';
    const response = await Http.get(api);
    this.setState({ isLoaded: true, isLogged: true, users: response.data });
  }
  async updateState(event) {
  }
  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    //x+=this.state.user.timezone*60000;
    //return(x.toLocaleString().slice(16,24));
    return (x.toLocaleTimeString());
  }
  // toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    this.setState({ id: 0 });
  }
  toLocalTime = (last_login, timezone) => {
    if (last_login == "")
      return ("Exam Not Given");
    var x = new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T') + 'Z'));
    return (x.toLocaleString());
  }
  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toLocalLast = (last_login) => {
    if (last_login == "")
      return ("Exam Not Given");
    var x = new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T') + 'Z'));
    return (x.toLocaleString());
  }
  render() {
    if (this.state.isLogged) {
      const show = this.state.menu ? "active" : "";
      const showMenu = this.state.menu ? "content-active" : "";
      const usershow = this.state.userMenu ? "show" : "";
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Dashboard | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                        </a>
                      </div>{/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                              </div>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>{this.state.user.first_name.charAt(0)}{this.state.user.last_name.charAt(0)}</span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                    <span className="sub-text">{this.state.user.email}</span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                  <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                  <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\newlogin"><em className="icon ni ni-signout" onClick={() => localStorage.clear()} /><span>Logout</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>{/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + show} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                          </li>
                        </ul>{/* .nk-quick-nav */}
                      </div>{/* .nk-header-tools */}
                    </div>{/* .nk-header-wrap */}
                  </div>{/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className="nk-menu-item ">
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                <span className="nk-menu-text">Student Dashboard</span>
                              </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                <span className="nk-menu-text">Exam Dashboard</span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item"><a href="\newmonidash" className="nk-menu-link"><span className="nk-menu-text">Registered Students</span></a></li>
                                <li className="nk-menu-item "><a href="\livedash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                                <li className="nk-menu-item"><a href="#studentsneedingattention" className="nk-menu-link"><span className="nk-menu-text">Suspicious Students</span></a></li>
                                <li className="nk-menu-item active"><a href="\newcompleted" className="nk-menu-link"><span className="nk-menu-text">Completed Students</span></a></li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="/newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                              </a>
                            </li>
                          </ul>
                        </div>{/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                        </div>{/* .nk-aside-close */}
                      </div>{/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title">Completed Exam Dashboard</h5>
                              </div>
                            </div>
                            <div className="container">
                              <div className="row">
                                  <div className="col-sm-4">
                                    <p className="exam-name mb-1">ID: EXAM00{this.state.exam.id}</p>
                                    <h6 className="text-primary pl-4">{this.state.exam.name}</h6>
                                  </div>
                                <div className="nk-block-des card card-bordered pr-20 pl-20 pt-20 pb-20 d-none" style={{ backgroundColor: "#f3f6ff" }}>
                                  <p class="d-none">View <em>{this.state.exam.name}</em> below:</p>
                                  <div className="col-3">
                                    <p className="exam-name mb-1">ID: EXAM00{this.state.exam.id}</p>
                                  </div>
                                  <div className="col-3 d-none">
                                    <p className="attendance-marked">Date: {(this.state.exam.date !== undefined) ? Intl.DateTimeFormat().format(Date.parse(this.state.exam.date) + Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z")) : ""} {this.state.exam.start_time !== undefined ? this.toLocal(this.state.exam.start_time) : "Exam not Scheduled"}</p>
                                  </div>
                                  <div className="col-3 d-none">
                                    <p className="attendance-marked">Type: {this.state.exam.platform}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{/* .nk-block-head */}
                          <div class="nk-block">
                            <div class="card card-bordered">
                              <table class="table table-member">
                                <thead class="tb-member-head thead-light">
                                  <tr class="tb-member-item">
                                    <th class="tb-member-action">
                                      <span class="overline-title">Live Photo</span>
                                    </th>
                                    <th class="tb-member-info d-none">
                                      <span class="overline-title d-md-inline-block d-none">ID</span>
                                    </th>
                                    <th>
                                      <span class="overline-title d-md-none">Name</span>
                                      <span class="overline-title d-md-inline-block">Student Details</span>
                                    </th>
                                    <th class="tb-member-role d-none">
                                      <span class="overline-title">Reg Photo</span>
                                    </th>
                                    <th class="tb-member-action d-none">
                                      <span class="overline-title">Admit Card</span>
                                    </th>
                                    <th class="tb-member-action d-none">
                                      <span class="overline-title">Attendance</span>
                                    </th>
                                    <th class="tb-member-action">
                                      <span class="overline-title">Remarks</span>
                                    </th>
                                    <th class="tb-member-action" >
                                      <span class="overline-title" style={{ display: (this.state.exam.platform == "Offline") ? "" : "" }}>Submission</span>
                                    </th>
                                    <th class="tb-member-action">
                                      <span class="overline-title">Test Score</span>
                                    </th>

                                  </tr>
                                </thead>
                                <tbody class="tb-member-body">
                                  <>
                                    {
                                      this.state.users.map(user => (
                                        <tr class="tb-member-item">
                                          <td class="tb-member-role tb-col-md">
                                            <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/' + user.live_pic.split("'")[1]} width="150px" />
                                          </td>
                                          <td class="tb-member-info d-none">
                                            <span class="sub-text d-md-none"><img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/' + user.reg_pic.split("'")[1]} width="120px" style={{ borderRadius: "50%" }} /></span>
                                            <span class="sub-text d-md-none">{user.cred}</span>
                                            <span class="sub-text d-md-none" style={{ display: (this.state.exam.platform == "Offline") ? "" : "" }}>{user.answer}</span>
                                            <span style={{ display: this.state.user.role == 'invig' ? "block" : "none" }} class="sub-text d-md-none">Doubtful</span>
                                            <span class="d-xs-none">STUD00{user.id}</span>
                                          </td><td>
                                          <div className="user-info" style={{ paddingLeft: "30px" }}>
                                            <a href={"#student" + user.id}><span className="lead-text">{user.first_name} {user.last_name}</span></a>
                                            <span className="sub-text">STUD00{user.id}</span>
                                            <span className="sub-text">{user.email}</span>
                                            <span className="sub-text d-none">{user.otp}</span>
                                            <span className="sub-text contact ">{user.status}</span>
                                            <span className="sub-text date ">{this.toLocalLast(user.last_login)}</span>
                                            <span className="sub-text contact ">{user.cred}</span>
                                            
                                    <span className="overline-title d-md-inline-block d-none"><input type="text" name="remarks" className="sub-text d-md-none contact" id="customCheck2" placeholder="Remarks" onChange={this.handleChange} /></span> 
                                            <span className="sub-text contact d-md-none"><a href="#errorMsg" className="btn btn-outline-primary" onClick={() => this.updateRemarks(user)}>Send</a></span>
                                          </div>
                                          </td>
                                          <td class="tb-member-role d-none">
                                            <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/' + user.reg_pic.split("'")[1]} width="150px" />
                                          </td>
                                          <td class="tb-member-role d-none">
                                            <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads' + user.admit_card} width="150px" />
                                          </td>
                                          <td class="tb-member-role d-none">
                                            <span>{user.status}</span>
                                          </td>
                                          <td class="tb-member-role tb-col-md">
                                            <span style={{ display: this.state.user.role == 'invig' ? "block" : "none" }} >{user.remarks}</span>
                                          </td>
                                          <td class="tb-member-role tb-col-md">
                                            <span style={{ display: (this.state.exam.platform == "Offline") ? "" : "" }}>{user.answer}</span>
                                          </td>
                                          <td class="tb-member-action">
                                            <span style={{ display: this.state.user.role == 'invig' ? "block" : "none" }} >{user.cred}</span>
                                          </td>

                                        </tr>
                                      ))}
                                  </>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    }
    else {
      return (<NewLogin />);
    }
  }
}
export default NewExamCompleted

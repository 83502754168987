import React from 'react'
import ReactDom from 'react-dom'


function Header() {
	return(
		<h3 className="header">Invigilation Management System</h3>
	)
}

export default Header 

import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import NewProfile from '../Auth/NewProfile'
import NewLogin from '../Auth/NewLogin'
import HowItWorks from '../Home/HowItWorks'
import FlexiExamDashboard from '../Dashboard/FlexiExamDashboard'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import LocalPDFFilePreview from './LocalPDFFilePreview'  
import ViewSDKClient from "./ViewSDKClient";
/* Exam Creation process */
class NewForm extends React.Component{
  constructor(){
  super();
  this.state=
      { isLogged:false,
        version:'1.01',
        checked:true,
        inputDate:'text',
        inputTime:'text',
        last_name:"",
        email:"she@email.com",
        mobile:"1111122222",
        id:1,
        password:"password",
        rePassword:"re-",
        status:true,
        dateSet:false, 
        camera_stateR:true, 
        network_stateR:true, 
        battery_stateR:true, 
        ipR:true, 
        latR:true,
        userMenu:false,
        menu:false
    };
    this.viewSDKClient = new ViewSDKClient();
    this.toggleMenu=this.toggleMenu.bind(this);
    this.toggleUserMenu=this.toggleUserMenu.bind(this);
  }
	    /* Helper function to check if selected file is PDF or not. */
  isValidPDF = file => {
    if (file.type === "application/pdf") {
      return true;
    }
    if (file.type === "" && file.name) {
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      if (lastDotIndex === -1 || fileName.substr(lastDotIndex).toUpperCase() !== "PDF") return false;
      return true;
    }
    return false;
  };

  /* Helper function to be executed on file upload
    * for creating Promise which resolve to ArrayBuffer of file data.
    * for larger files increase nginx conf to 25M 
    */
  onFileUpload = (event) => {
    event.persist();
    this.viewSDKClient.ready().then(() => {
    const files = event.target.files;
    if (files.length > 0 && this.isValidPDF(files[0])) {
      const fileName = files[0].name;
      const reader = new FileReader();
      reader.onloadend = e => {
        const filePromise = Promise.resolve(e.target.result);
        /* Helper function to render the file using PDF Embed API. */
        this.viewSDKClient.previewFileUsingFilePromise("pdf-div", filePromise, fileName);
      };
      reader.readAsArrayBuffer(files[0]);
    }
    });
    this.uploadQuestionPaper(event);
  }

    handleChange = (event)=>{
    const errorMsgElement = document.querySelector('span#errorMsg');
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
		//error reported by Test
    //errorMsgElement.innerHTML=val;
		if(nam=="date")
		{
			this.setState({dateSet:true});
		}
	}
  //This function is used to detect checkboxes
	handleChecked = (event)=>{
		let nam=event.target.name;
		this.setState({[nam]:!this.state.checked});
	}
  uploadQuestionPaper=(e)=>{
		var data=new FormData();
    const errorMsgElement = document.querySelector('span#errorMsg');
		data.append('file',e.target.files[0]);
		console.log(e.target.files[0]);
		//this.setState({fileQuestionPaper:URL.createObjectURL(e.target.files[0])});
		//console.log(this.state.fileQuestionPaper);
      Http.post('exam-api/examq',data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        errorMsgElement.innerHTML=res.data;
        this.setState({link:res.data});
      })
      .catch(function (error) {
        errorMsgElement.innerHTML=error;
        console.log(error);
      });
  }
	componentWillMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user)
      this.state.user = user;
		if(user)
      this.state.isLogged=true;
		else{
	    console.log("User not Logged in");
      this.state.isLogged=false;
		}
	}
	handleSubmit = (event)=>{
		event.preventDefault();
    const errorMsgElement = document.querySelector('span#errorMsg');
		//Convert the google pdf link to google preview link
    //TODO: Google Doc is no longer an option
    if(this.state.platform =="GoogleDoc") {
		  if(this.state.link.lastIndexOf("/view")==this.state.link.indexOf("/view")) 
			  this.state.link.replace("/view","/preview");
		}

    if((this.state.link===undefined) && (this.state.platform=="Offline"))
    {
      errorMsgElement.innerHTML="Exam creation Failed. Please ensure that the pdf file uploaded is < 25 MB";
    }
    if(this.state.platform=="Flexi")
    {
		    var x=new Date(Date.parse(this.state.window_start_date)+Date.parse("1970-01-01T"+this.state.window_start_time+"Z"));
		    var x=new Date(Date.parse(this.state.window_start_date+"T"+this.state.window_start_time+"Z"));
		    var y=new Date(x.getTime()+x.getTimezoneOffset()*60000);
		    this.state.window_start_date=y.toISOString();
		    let time=this.state.window_start_date.split("T");
		    this.state.window_start_date=time[0];
		    time=time[1].split(".");
		    this.state.window_start_time=time[0];
      Http.post('exam-api/examf',{
        "name":this.state.name,
        "platform":this.state.platform,
        "window_start_time":this.state.window_start_time,
        "window_start_date":this.state.window_start_date,
        "window_duration":this.state.window_duration,
        "duration":this.state.duration,
        "link":this.state.link,
        "camera_stateR":this.state.camera_stateR,
        "network_stateR":this.state.network_stateR,
        "battery_stateR":this.state.battery_stateR,
        "ipR":this.state.ipR,
        "latR":this.state.latR,
        "invig_id":this.state.user.id,
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
  .then(res => {
        const response = res.data;
        this.setState({ exam:response,status:false});
    //    console.log("Exam addition successful");
        errorMsgElement.innerHTML="Flexi Exam Scheduled Successfully";
        return <Redirect to="/flexi" />
      })
  .catch(function (error) {
        this.setState({ status:true});
        console.log(error);
        errorMsgElement.innerHTML=error;
      });
    } else if(this.state.dateSet) //scheduled
		{
		    var x=new Date(Date.parse(this.state.date)+Date.parse("1970-01-01T"+this.state.start_time+"Z"));
		    var x=new Date(Date.parse(this.state.date+"T"+this.state.start_time+"Z"));
		    var y=new Date(x.getTime()+x.getTimezoneOffset()*60000);
		    this.state.date=y.toISOString();
		    let time=this.state.date.split("T");
		    this.state.date=time[0];
		    time=time[1].split(".");
		    this.state.start_time=time[0];
		    //console.log(this.state.start_time);
		    //console.log(this.state.date);

		    Http.post('exam-api/exam',{
		          "name":this.state.name,
		          "platform":this.state.platform,
		          "date":this.state.date,
		          "start_time":this.state.start_time,
		          "duration":this.state.duration,
		          "link":this.state.link,
		          "camera_stateR":this.state.camera_stateR,
		          "network_stateR":this.state.network_stateR,
		          "battery_stateR":this.state.battery_stateR,
		          "ipR":this.state.ipR,
		          "latR":this.state.latR,
			        "invig_id":this.state.user.id,
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		    .then(res => {
              const response = res.data;
              this.setState({ exam:response,status:false});
		      //    console.log("Exam addition successful");
		          errorMsgElement.innerHTML="Exam Scheduled Successfully";
	            return <Redirect to="/flexi" />
            })
        .catch(function (error) {
              this.setState({ status:true});
              console.log(error);
		          errorMsgElement.innerHTML=error;
            });
		} else { //unscheduled
		Http.post('exam-api/exam',{
		          "name":this.state.name,
		          "platform":this.state.platform,
		          "duration":this.state.duration,
		          "link":this.state.link,
		          "camera_stateR":this.state.camera_stateR,
		          "network_stateR":this.state.network_stateR,
		          "battery_stateR":this.state.battery_stateR,
		          "ipR":this.state.ipR,
		          "latR":this.state.latR,
			        "invig_id":this.state.user.id,
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		.then(res => {
      const response = res.data;
      this.setState({ exam:response,status:false});
		  console.log("Exam addition successful");
		  errorMsgElement.innerHTML="Exam Created Successfully";
      return <Redirect to="/newexamdash" />
    })
    .catch(function (error) {
        this.setState({ status:true});
        console.log(error);
        errorMsgElement.innerHTML=error;
      });
		} 
	}
    toggleUserMenu() {
        this.setState({userMenu:!this.state.userMenu});  
    }
    toggleMenu() {
        this.setState({menu:!this.state.menu});  
    }
  render() {
  if(this.state.isLogged)
  {
    if(this.state.status)
    {
        const show = this.state.menu ? "active" : "";
        const showMenu = this.state.menu ? "content-active" : "";
        const usershow = this.state.userMenu ? "show" : "";            
        return (
          <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Create A Exam | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                        </a>
                      </div>{/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                              </div>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>{this.state.user.first_name.charAt(0)}{this.state.user.last_name.charAt(0)}</span>
                                  </div>
                                  <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                  <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                  <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                <li><a href="#"><em className="icon ni ni-signout" onClick={()=>localStorage.clear()}/><span>Sign out</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>{/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a href="#" className={"toggle nk-quick-nav-icon mr-n1 "+this.state.menu?"active":""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                          </li>                        
                        </ul>{/* .nk-quick-nav */}
                      </div>{/* .nk-header-tools */}
                    </div>{/* .nk-header-wrap */}
                  </div>{/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div className={"nk-aside "+showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-item">
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                <span className="nk-menu-text">Student DashBoard</span>
                              </a>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                <span className="nk-menu-text">Exam Dashboard</span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Anytime Exams</span></a></li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\login" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                <span className="nk-menu-text" onClick={()=>localStorage.clear()} >Log Out</span>
                              </a>
                            </li>
                          </ul>
                        </div>{/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                        </div>{/* .nk-aside-close */}
                      </div>{/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h2 className="nk-block-title">Add New Exam</h2>
                                <div className="nk-block-des">
                                  <p>You can create new exams depending upon your requirement.</p>
                      <p><span id="errorMsg"></span></p>
                                </div>
                              </div>
                              <div className="nk-block-head-content">
                                <ul className="nk-block-tools gx-3">
                                  <li />
                                </ul>
                              </div>
                            </div>
                          </div>{/* .nk-block-head */}
                          <div className="nk-block">
                            <div className="card card-bordered create-form-box">
                              <h4 className="text-primary text-center mb-4">Create Exam</h4>
                              <form onSubmit={this.handleSubmit}  action="verification-room.html">
                                <div className="row">
                                  <div className="col-sm-8 form-group">
                                    <span className="form-icon"><em className="icon ni ni-file" /></span>
                                    <input type="text" name='name' onChange={this.handleChange} className="form-control form-control-lg" id="examname" placeholder="Exam Name*" />
                                  </div>
                                  <div className="col-sm-4 form-group create-exam-select">
                                    <span className="form-icon"><em className="icon ni ni-grid-sq" /></span>
                                    <select type="text" className="form-control create-exam" name='type' id="examtype"  onClick={this.handleChange} onChange={this.handleChange} required>
                                      <option value="" selected>Choose Proctored Type</option>
                                      <option value="auto_proctored">Auto Proctored</option>
                                      <option value="live_proctored">Live Proctored</option>
                                    </select>
                                  </div>
                                  <div className="col-sm-4 form-group create-exam-select">
                                    <span className="form-icon"><em className="icon ni ni-grid-sq" /></span>
                                    <select type="text" className="form-control create-exam" name='platform' id='platform' onClick={this.handleChange} onChange={this.handleChange} required>
                                      <option value="" selected>Choose Exam Type</option>
                                      <option value="Online">Online</option>
                                      <option value="Offline">Offline</option>
                                      <option value="Unscheduled">Unscheduled Exam</option>
                                      <option value="Flexi">Flexi Exam</option>
                                      <option value="Zoom">Interview</option>
                                    </select>
                                  </div>
                                  <div style={{display:this.state.platform=="Offline"?"none":""}} className="col-sm-8 form-group type-online">
                                    <span className="form-icon"><em className="icon ni ni-link-alt" /></span>
                                    <input type="url" name='link' onChange={this.handleChange} className="form-control form-control-lg" id="link" placeholder="Enter Link"/> 
                                  </div>
                                  <div className={"col-sm-8 form-group type-offline "+(this.state.platform=="Offline"?"active":"noactive")}>
                                    <div id="pdf-div" className="full-window-div"  style={{display:this.state.platform=="Offline"?"block":"none"}}>
                                      <div className="form-control-wrap">
                                        <div className="custom-file">
                                          {/* Listen for file upload */}
                                          <input onChange={ this.onFileUpload } type="file" id="file-picker" name="file-picker" accept="application/pdf" style={{display:this.state.platform=="Offline"?"block":"none"}} id="customFile" className="btn btn-outline-secondary file-input custom-file-input"/>
                                          <label for="customFile" className="custom-file-label">Upload Question Paper</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={"col-sm-4 form-group "+(((this.state.platform=="Unscheduled")||(this.state.platform=="Flexi"))?"noactive":"active")}>
                                    <span className="form-icon"><em className="icon ni ni-calender-date" /></span>
                                    <input type={this.state.inputDate} className={"form-control form-control-lg date-picker "} id="date" name="date" placeholder="Select Date" onFocus={()=>this.setState({inputDate:'date'})} onChange={this.handleChange}/>
                                  </div>
                                  <div className={"col-sm-4 form-group "+(((this.state.platform=="Unscheduled")||(this.state.platform=="Flexi"))?"noactive":"active")}>
                                    <span className="form-icon"><em className="icon ni ni-clock" /></span>
                                    <input type={this.state.inputTime} className={"form-control form-control-lg time-picker "} name="start_time" id="start_time" placeholder="Select Time" onFocus={()=>this.setState({inputTime:'time'})} onChange={this.handleChange} />
                                  </div>
                                  <div className={"col-sm-4 form-group "+(this.state.platform=="Flexi"?"active":"noactive")}>
                                    <span className="form-icon"><em className="icon ni ni-calender-date" /></span>
                                    <input type={this.state.inputDate} className={"form-control form-control-lg date-picker "} id="window_start_date" name="window_start_date" placeholder="Select Earliest Date" onFocus={()=>this.setState({inputDate:'date'})} onChange={this.handleChange}/>
                                  </div>
                                  <div className={"col-sm-4 form-group "+(this.state.platform=="Flexi"?"active":"noactive")}>
                                    <span className="form-icon"><em className="icon ni ni-clock" /></span>
                                    <input type={this.state.inputTime} className={"form-control form-control-lg time-picker "} name="window_start_time" id="window_start_time" placeholder="Select Earliest Time to start" onFocus={()=>this.setState({inputTime:'time'})} onChange={this.handleChange} />
                                  </div>
                                  <div className={"col-sm-4 form-group " +(this.state.platform=="Flexi"?"active":"noactive")}>
                                    <span className="form-icon"><em className="icon ni ni-alarm" /></span>
                                    <input type="text" className="form-control form-control-lg" name="window_duration" id="window_duration" placeholder="Duration of Window to start Exam" onChange={this.handleChange} />
                                  </div>
                                  <div className={((this.state.platform=="Unscheduled"||this.state.platform=="Flexi")?"col-sm-12":"col-sm-4")+" form-group"}>
                                    <span className="form-icon"><em className="icon ni ni-alarm" /></span>
                                    <input type="text" className="form-control form-control-lg" name="duration" id="duration" placeholder="Duration in Minutes" onChange={this.handleChange} required/>
                                  </div>
                                  <div className="col-sm-12 form-group noactive">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                      <label className="custom-control-label" htmlFor="customCheck1">Add another slot</label>
                                    </div>
                                  </div>
                                  <div className="col-sm-4 form-group extra-field noactive">
                                    <span className="form-icon"><em className="icon ni ni-calender-date" /></span>
                                    <input type="tel" className="form-control form-control-lg date-picker" id="date" placeholder="Select Date" />
                                  </div>
                                  <div className="col-sm-4 form-group extra-field noactive">
                                    <span className="form-icon"><em className="icon ni ni-clock" /></span>
                                    <input type="tel" className="form-control form-control-lg time-picker" id="time" placeholder="Select Time" />
                                  </div>
                                  <div className="col-sm-4 form-group extra-field noactive">
                                    <span className="form-icon"><em className="icon ni ni-alarm" /></span>
                                    <input type="tel" className="form-control form-control-lg" id="duration" placeholder="Duration in Minutes" />
                                  </div>
                                  <div className="col-sm-12 form-group noactive">
                                    <label>Additional Features</label>
                                    <div className="form-control-wrap checkbox">
                                      <div className="custom-control custom-checkbox"> 
                                        <input type="checkbox" className="custom-control-input" id="photo" />    <label className="custom-control-label" htmlFor="photo">Photo</label>
                                      </div>
                                      <div className="custom-control custom-checkbox"> 
                                        <input type="checkbox" className="custom-control-input" id="location" />    <label className="custom-control-label" htmlFor="location">Location</label>
                                      </div>
                                      <div className="custom-control custom-checkbox"> 
                                        <input type="checkbox" className="custom-control-input" id="ipaddress" />    <label className="custom-control-label" htmlFor="ipaddress">IP Address</label>
                                      </div>
                                      <div className="custom-control custom-checkbox"> 
                                        <input type="checkbox" className="custom-control-input" id="battery" />    <label className="custom-control-label" htmlFor="battery">Battery</label>
                                      </div>
                                      <div className="custom-control custom-checkbox"> 
                                        <input type="checkbox" className="custom-control-input" id="internetspeed" />    <label className="custom-control-label" htmlFor="internetspeed">Internet Speed</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 form-group text-right">
                                    <button type="submit" className="btn btn-lg btn-primary">Add Exam</button>
                                  </div>
                                </div>
                              </form>{/* form */}
                            </div>
                          </div>
                        </div>
                        {/* footer @e */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* content @e */}
              </div>
              {/* wrap @e */}
            </div>
            {/* main @e */}
          </div>
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    }
    else
      return (<FlexiExamDashboard />);
  }
  else
    return(<NewLogin />);
  }
}

export default NewForm
